import { Button, Col, Form, Select, Spin, Typography } from 'antd';
import { ReactComponent as MilaIcon } from '../../common/Icons/MilaIcon.svg';
import { OrganizationsAccess, ValidateErrorEntity } from '../../common/types';
import { oidcAuthService } from '../../app/services/auth/oidcAuthService';
import {
  setHospitalId,
  setMedOrganizationDoctorId,
  setMedOrganizationId,
  setNetwork,
  setSnils,
} from '../../app/services/redux/appSlice';
import { useAppDispatch } from '../../app/hooks';
import { useAuth } from 'react-oidc-context';
import { useEffect, useMemo } from 'react';
import { useGetOrganizationsQuery } from '../../app/services/api';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';

const NetworkSelection = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const authService = oidcAuthService;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data, isLoading: organizationsLoading } = useGetOrganizationsQuery('');

  const organizations: OrganizationsAccess[] = useMemo(() => (data ? data : []), [data]);

  useEffect(() => {
    if (isAuthenticated && organizations.length === 1) {
      setDataAndNavigate(organizations[0]);
    }
  }, [isAuthenticated, isLoading, organizations]);

  const onFinish = (v: any) => {
    const item = organizations.find((x: OrganizationsAccess) => x.medOrganizationDoctorId === v.selectedOption);

    setDataAndNavigate(item);
  };

  const setDataAndNavigate = (item: OrganizationsAccess | undefined) => {
    if (item) {
      dispatch(setNetwork(item.networkId));
      dispatch(setMedOrganizationId(item.medOrganizationId));
      dispatch(setHospitalId(item.medOrganizationId));
      dispatch(setSnils(item.snils));
      dispatch(setMedOrganizationDoctorId(item.medOrganizationDoctorId));
      navigate('/');
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.log('Failed:', errorInfo);
  };

  const prepareOptionsList = useMemo(
    () =>
      organizations.map((item: OrganizationsAccess) => ({
        label: item.title,
        value: item.medOrganizationDoctorId,
      })),
    [organizations],
  );

  const handleLogout = async () => {
    await authService.logout();
  };

  return (
    <div className={styles.authFormWrapper}>
      <div className={styles.formNetworkContainer}>
        <Col className={styles.logo}>
          <MilaIcon />
        </Col>
        {(isLoading || organizationsLoading) && <Spin size="large" />}
        {!organizations.length && !organizationsLoading ? (
          <>
            <Col className={styles.formTitle}>
              <Typography.Text>Извините, у Вас нет прав доступа</Typography.Text>
            </Col>
            <Col className={styles.inputWrapper}>
              <Button onClick={handleLogout} className={styles.loginButton}>
                Ок
              </Button>
            </Col>
          </>
        ) : (
          <>
            <Col className={styles.formTitle}>
              <Typography.Text>Выберите куда подключиться</Typography.Text>
            </Col>
            <Col className={styles.inputWrapper}>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                 data-yl-ignore-form="true"
              >
                <Form.Item
                  name="selectedOption"
                  rules={[{ required: true, message: 'Пожалуйста выберите куда подключиться!' }]}
                >
                  <Select
                    disabled={!organizations.length}
                    className={styles.SelectNetwork}
                    placeholder={'Выберите куда подключиться'}
                    options={prepareOptionsList}
                  />
                </Form.Item>

                <Form.Item>
                  <Button htmlType="submit" className={styles.loginButton}>
                    Вход
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </>
        )}
      </div>
    </div>
  );
};

export default NetworkSelection;
