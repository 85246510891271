import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';

const renderMicroFrontend = (name: string, history: any) => {
  // @ts-ignore
  window[`render${name}`](`${name}-container`, history);
};

type MicroFrontendProps = {
  name: string;
  host: string;
  resetStyle?: boolean;
};

export const MicroFrontend = ({ name, host, resetStyle = false }: MicroFrontendProps) => {
  const scriptId = `micro-frontend-script-${name}`;
  const configScriptId = `micro-frontend-config-script-${name}`;
  const styleId = `micro-frontend-style-${name}`;

  const navigate = useNavigate();

  useEffect(() => {
    if (document.getElementById(scriptId)) {
      renderMicroFrontend(name, navigate);
      return;
    }
    fetch(`${host}/asset-manifest.json`)
      .then((res) => res.json())
      .then((manifest) => {
        const scriptConfig = document.createElement('script');
        scriptConfig.src = `${host}/config.js`;
        scriptConfig.id = configScriptId;
        scriptConfig.crossOrigin = '';
        document.head.appendChild(scriptConfig);
        const script = document.createElement('script');
        script.id = scriptId;
        script.crossOrigin = '';
        const hostElements = host.split('/');
        const srcElements: string[] = [];
        manifest.files['main.js']?.split('/')?.forEach((f: string) => {
          if (!hostElements.includes(f)) {
            srcElements.push(f);
          }
        });
        script.src = `${host}/${srcElements.join('/')}`;
        scriptConfig.onload = () => {
          script.onload = () => {
            renderMicroFrontend(name, navigate);
          };
        };
        document.head.appendChild(script);

        if (manifest.files['main.css']) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          const hrefElements: string[] = [];
          manifest.files['main.css']?.split('/')?.forEach((f: string) => {
            if (!hostElements.includes(f)) {
              hrefElements.push(f);
            }
          });
          link.href = `${host}/${hrefElements.join('/')}`;
          link.id = styleId;
          document.head.appendChild(link);
        }
      });

    return () => {
      // @ts-ignore
      window[`unmount${name}`] && window[`unmount${name}`](`${name}-container`);
      document.getElementById(styleId)?.remove();
      document.getElementById(scriptId)?.remove();
      document.getElementById(configScriptId)?.remove();
    };
  }, []);

  return <main id={`${name}-container`} className={`${styles.microfrontend} ${resetStyle ? styles.reset : ''}`} />;
};
