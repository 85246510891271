import { Avatar, Button, Col, Drawer, Layout, Menu, MenuProps, Space, Typography } from 'antd';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { ReactComponent as MilaIcon } from '../../common/Icons/MilaIcon.svg';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router';
import { useState } from 'react';

import { oidcAuthService } from '../../app/services/auth/oidcAuthService';
import styles from './styles.module.scss';

const { Header } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

const renderUserInfo = (userName: string) => {
  return (
    <Space>
      <div className={styles.userNameContainer}>
        <Typography.Text>{userName}</Typography.Text>
      </div>
      <div>
        <Avatar shape="circle" src="" size={44} className={styles.userAvatar}>
          {userName?.charAt(0).toLocaleUpperCase()}
        </Avatar>
      </div>
    </Space>
  );
};

const NavBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const authService = oidcAuthService;
  const loggedUserName = auth.user?.profile.preferred_username || '';

  const handleLogout = async () => await authService.logout();

  const handleChangePassword = async () => (window.location.href = await authService.changeUserPasswordUrl());

  const handleRedirectToNetworkSelection = () => navigate('/networkSelection');

  const itemsUserMenu: MenuItem[] = [
    {
      key: 'userName',
      icon: renderUserInfo(loggedUserName),
      children: [
        {
          label: 'Сменить пароль',
          key: 'changePassword',
          onClick: handleChangePassword,
        },
        {
          label: 'Сменить организацию',
          key: 'changeOrganization',
          onClick: handleRedirectToNetworkSelection,
        },
        {
          label: <span style={{ color: '#FA6060' }}>Выйти</span>,
          key: 'logout',
          onClick: handleLogout,
        },
      ],
    },
  ];

  const mobileMenuItems: MenuItem[] = [
    {
      key: 'home',
      label: (
        <Link to="/" onClick={() => setMobileMenuOpen(false)}>
          ТЕЛЕМЕДИЦИНСКИЕ КОНСУЛЬТАЦИИ
        </Link>
      ),
    },
    {
      key: 'researches',
      label: (
        <Link to="/researches" onClick={() => setMobileMenuOpen(false)}>
          КЛИНИЧЕСКИЕ ИССЛЕДОВАНИЯ
        </Link>
      ),
    },
    {
      key: 'support',
      label: (
        <a
          href="mailto:support@n3health.ru"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setMobileMenuOpen(false)}
        >
          Техподдержка
        </a>
      ),
    },
    {
      key: 'manuals',
      label: (
        <a
          href="https://mila.online/manuals"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setMobileMenuOpen(false)}
        >
          Инструкции
        </a>
      ),
    },
    {
      type: 'divider',
      key: 'divider-1',
    },
    {
      key: 'changePasswordMobile',
      label: 'Сменить пароль',
      onClick: () => {
        setMobileMenuOpen(false);
        handleChangePassword();
      },
    },
    {
      key: 'changeOrgMobile',
      label: 'Сменить организацию',
      onClick: () => {
        setMobileMenuOpen(false);
        handleRedirectToNetworkSelection();
      },
    },
    {
      key: 'logoutMobile',
      label: <span style={{ color: '#FA6060' }}>Выйти</span>,
      onClick: () => {
        setMobileMenuOpen(false);
        handleLogout();
      },
    },
  ];

  return (
    <Layout className={styles.navBarContainer}>
      <Header className={styles.headerContainer}>
        <Col className={styles.logo}>
          <MilaIcon />
        </Col>

        <Col className={styles.linksContainer}>
          <Typography.Link href="mailto:support@n3health.ru" target="_blank" className={styles.LabelsWrapper}>
            Техподдержка
          </Typography.Link>
          <Typography.Link href="https://mila.online/manuals" target="_blank" className={styles.LabelsWrapper}>
            Инструкции
          </Typography.Link>
        </Col>

        <div className={styles.headerSplitter} />

        <Col className={styles.widgetLinksContainer}>
          <Link
            to="/"
            className={`${styles.LinkLabelsWrapper}${
              location.pathname === '/' ? ` ${styles.LinkLabelsWrapperActive}` : ''
            }`}
          >
            ТЕЛЕМЕДИЦИНСКИЕ КОНСУЛЬТАЦИИ
          </Link>
          <Link
            to="/researches"
            className={`${styles.LinkLabelsWrapper}${
              location.pathname === '/researches' ? ` ${styles.LinkLabelsWrapperActive}` : ''
            }`}
          >
            КЛИНИЧЕСКИЕ ИССЛЕДОВАНИЯ
          </Link>
        </Col>

        <Col className={styles.userInfoContainer}>
          <Menu className={styles.Menu} items={itemsUserMenu} triggerSubMenuAction="click" mode="horizontal" />
        </Col>

        <Col className={styles.burgerMenuIcon}>
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: '24px' }} />}
            onClick={() => setMobileMenuOpen(true)}
          />
        </Col>
      </Header>

      <Drawer
        headerStyle={{ padding: '18px 24px' }}
        placement="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        closable={false}
        bodyStyle={{ padding: 0 }}
        title={
          <div className={styles.drawerTitleContainer} style={{ display: 'flex', justifyContent: 'space-between' }}>
            {renderUserInfo(loggedUserName)}
            <CloseOutlined style={{ fontSize: 24, cursor: 'pointer' }} onClick={() => setMobileMenuOpen(false)} />
          </div>
        }
      >
        <Menu mode="inline" items={mobileMenuItems} onClick={() => setMobileMenuOpen(false)} />
      </Drawer>

      <Col>
        <Layout>
          <Outlet />
        </Layout>
      </Col>
    </Layout>
  );
};

export default NavBar;
