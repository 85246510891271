import { MicroFrontend } from '../../common/Components/MicroFrontend/MicroFrontend';
import { Spin } from 'antd';
import { useAppSelector } from 'src/app/hooks';
import { useEffect, useState } from 'react';
import { useGetSessionIdQuery } from '../../app/services/api';

const RequestsPage = ({ widgetUrl, resetStyle = false }: { widgetUrl: string; resetStyle?: boolean }) => {
  const moId = useAppSelector((state) => state.app.hospitalId) || '';
  const milaMedOrganizationDoctorId = useAppSelector((state) => state.app.medOrganizationDoctorId) || '';
  const [session, setSession] = useState<any>(null);

  const { data: sessionId } = useGetSessionIdQuery({
    idMedOrganization: moId,
    idMilaMedOrganizationDoctor: milaMedOrganizationDoctorId,
  });

  useEffect(() => {
    if (sessionId) {
      setTimeout(() => setSession(sessionId), 1000);
    }
  }, [sessionId]);

  const listener = () => {
    try {
      window?.postMessage(
        {
          isActualData: true,
          data: {
            sessionId: session,
          },
        },
        '*',
      );
    } catch (e) {
      console.error(e);
    }
  };

  const loadIframe = ({ data }: { data: string }) => {
    if (data === 'load') {
      listener();
    }
  };

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', loadIframe, false);
    }
    return () => window.removeEventListener('message', loadIframe, false);
  }, [session]);

  return session ? (
    <MicroFrontend name="TMWidget" host={widgetUrl} resetStyle={resetStyle} />
  ) : (
    <Spin size="large" className="pageLoader" />
  );
};
export default RequestsPage;
