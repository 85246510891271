import { FC, ReactNode } from 'react';
import { Typography } from 'antd';

type AbsoluteLinkProps = {
  href: string;
  children: ReactNode;
  className?: string;
  target?: string;
  rel?: string;
};

export const AbsoluteLink: FC<AbsoluteLinkProps> = ({
  href,
  children,
  className,
  target = '_blank',
  rel = 'noopener noreferrer',
}) => {
  return (
    <Typography.Link href={href} target={target} rel={rel} className={className}>
      {children}
    </Typography.Link>
  );
};
