import { AbsoluteLink } from 'src/common/Components/AbsoluteLink';
import { ReactComponent as MilaWhiteIcon } from '../../common/Icons/MilaWhiteIcon.svg';
import { ReactComponent as N3HealthIcon } from '../../common/Icons/N3HealthIcon.svg';
import { Space } from 'antd';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

const currentYear = dayjs().format('YYYY');

function Footer() {
  return (
    <footer className={styles.footerContainer}>
      <Space>
        <AbsoluteLink href="https://n3health.ru/">
          <N3HealthIcon />
        </AbsoluteLink>
      </Space>
      <Space>
        <MilaWhiteIcon />
        <Space direction="vertical" size={0} style={{ fontWeight: 400 }}>
          <div>совместный проект</div>
          <div>
            <AbsoluteLink href="https://n3.group/">N3.Group</AbsoluteLink>
            <span>&nbsp;и&nbsp;</span>
            <AbsoluteLink href="https://tashir-medica.com/">Ташир.Медика</AbsoluteLink>
          </div>
        </Space>
      </Space>
      <Space direction="vertical" size={0} style={{ fontWeight: 300 }}>
        <AbsoluteLink href="https://drive.google.com/file/d/1aehL_w5qgA_Rw797xa6-7y8td_UXa0yg/view">
          Политика конфиденциальности
        </AbsoluteLink>
        <AbsoluteLink href="mailto:support@n3health.ru">Техподдержка</AbsoluteLink>
      </Space>
      <Space direction="vertical" size={0} style={{ fontWeight: 300 }}>
        <div>&copy; {currentYear} ООО "ЭлНетМед"</div>
        <div>Официальный оператор передачи данных в ЕГИСЗ</div>
      </Space>
    </footer>
  );
}

export default Footer;
